import React, { useState, useContext } from "react";
import { DataContext } from "../../utils/DataContext";
import {
  Paper,
  TextField,
  Tab,
  Tabs,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import Button from "@mui/material/Button";
import FullScreenLoader from "../layouts/FullScreenLoader";
import { useTheme } from "@mui/material";
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import WebComponent from "../layouts/WebComponent";
import MobileComponent from "../layouts/MobileComponent";
//import { FaLinkedin } from "react-icons/fa";
import logo from "../../assets/logo.png";
import kevinLogin from "../../assets/kevinLogin.svg";
import { useTranslation } from "react-i18next";

export default function Login() {
  const { t } = useTranslation();
  const { 
    login, 
    signup, 
    loading, 
    // linkedInLogin 
  } = useContext(DataContext);

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyTitle, setCompanyTitle] = useState("");
  const [gender, setGender] = useState("");
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSubmit = (e, tab) => {
    e.preventDefault();
    tab === 0 ? loginUser() : signupUser();
  };

  const loginUser = async () => {
    await login(email);
  };

  const signupUser = async () => {
    await signup(email, name, lastName, companyName, companyTitle, gender);
  };

  // const userLinkedInLogin = async () => {
  //   await linkedInLogin();
  // };

  const theme = useTheme();

  return (
    <>
      {loading ? (
        <FullScreenLoader />
      ) : (
        <>
          <WebComponent />
          <MobileComponent>
            <div className="flex flex-col relative w-full h-[100vh] items-center">
              <div className="w-full px-5 my-4">
                <img src={logo} alt="logo" className="w-[200px]" />
              </div>

              <Paper
                sx={{
                  position: "relative",
                  paddingX: "15px",
                  paddingBottom: "15px",
                  marginBottom: "35px",
                  paddingTop: "15px",
                  backgroundColor: theme.palette.background.default,
                  borderRadius: "15px",
                  overflowX: "hidden",
                  width: "90%",
                }}
              >
                <MdArrowBackIos
                  className="absolute left-5 top-5 w-[35px] h-[35px] z-50 fill-c1 hover:scale-105 cursor-pointer"
                  onClick={() => navigate("/")}
                />
                <img
                  src={kevinLogin}
                  alt="kevin-login"
                  className="absolute top-[-250px] right-[-30px] w-[200px] mx-auto z-50"
                />
                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  <Tab label={t("logIn")} />
                  <Tab label={t("signUp")} />
                </Tabs>
                <form
                  className="flex flex-col space-y-8 mt-8"
                  onSubmit={(e) => handleSubmit(e, activeTab)}
                >
                  <div className="flex flex-col space-y-4">
                    <TextField
                      sx={{ minWidth: "300px" }}
                      label={t("email")}
                      variant="outlined"
                      type="email"
                      autoComplete="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {activeTab === 1 && (
                      <>
                        <TextField
                          sx={{ minWidth: "300px" }}
                          label={t("name")}
                          variant="outlined"
                          type="text"
                          autoComplete="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        <TextField
                          sx={{ minWidth: "300px" }}
                          label={t("lastName")}
                          variant="outlined"
                          type="text"
                          autoComplete="lastName"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                        <TextField
                          sx={{ minWidth: "300px" }}
                          label={t("companyName")}
                          variant="outlined"
                          type="text"
                          autoComplete="companyName"
                          value={companyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                        />
                        <TextField
                          sx={{ minWidth: "300px" }}
                          label={t("companyTitle")}
                          variant="outlined"
                          type="text"
                          autoComplete="companyTitle"
                          value={companyTitle}
                          onChange={(e) => setCompanyTitle(e.target.value)}
                        />
                        <FormControl sx={{ minWidth: "300px" }}>
                          <InputLabel id="gender-label">{t("gender")}</InputLabel>
                          <Select
                            labelId="gender-label"
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            label={t("gender")}
                          >
                            <MenuItem value="male">{t("male")}</MenuItem>
                            <MenuItem value="female">{t("female")}</MenuItem>
                          </Select>
                        </FormControl>
                      </>
                    )}
                  </div>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    sx={{
                      borderRadius: "10px",
                      fontSize: "19px",
                      fontWeight: "medium",
                      fontFamily: "Helvetica Neue",
                      color: "white",
                    }}
                    onClick={(e) => handleSubmit(e, activeTab)}
                  >
                    {activeTab === 0 ? t("logIn") : t("signUp")}
                  </Button>

                  {/* {activeTab === 0 && (
                    <div
                      className="mt-6 flex flex-row items-center justify-center rounded-[10px] text-white bg-c1 py-2 px-4 cursor-pointer hover:bg-white hover:text-c1"
                      onClick={() => userLinkedInLogin()}
                    >
                      <FaLinkedin className="w-[27px] h-[27px]" />
                      <p className="text-[20px] ml-4">{t("continueWithLinkedIn")}</p>
                    </div>
                  )} */}
                  {activeTab === 0 && (
                    <p className="text-center text-slate-600 font-thin">
                      {t("dontHaveAnAccount")}
                      <span
                        className="text-c1 cursor-pointer underline font-semibold"
                        onClick={() => setActiveTab(1)}
                      >
                         {t("signUp")}
                      </span>
                    </p>
                  )}
                  {activeTab === 1 && (
                    <p className="text-center text-slate-600 font-thin">
                      {t("alreadyHaveAnAccount")}
                      <span
                        className="text-c1 cursor-pointer underline font-semibold"
                        onClick={() => setActiveTab(0)}
                      >
                         {t("logIn")}
                      </span>
                    </p>
                  )}
                  <div className="text-[12px] text-slate-400 mt-8 text-center">
                    {t("byCreatingOrLoggingIn")}
                    <a
                      href="https://finconecta.atlassian.net/wiki/spaces/FCSM/pages/1650688002/Terms+Conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-c1 underline cursor-pointer mx-1"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(e.target.href);
                      }}
                    >
                      {t("termsAndConditions")}
                    </a>{" "}
                    {t("and")}
                    <a
                      href="https://finconecta.atlassian.net/wiki/spaces/FCSM/pages/1650589698/Privacy+Policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-c1 underline cursor-pointer mx-1"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(e.target.href);
                      }}
                    >
                      {t("privacyPolicy")}
                    </a>
                    .
                  </div>
                </form>
              </Paper>
              <p className="absolute bottom-[2px] left-[50%] text-[12px] transform translate-x-[-50%] text-white font-thin">
                Powered by Finconecta ®
              </p>
            </div>
          </MobileComponent>
        </>
      )}
    </>
  );
}
