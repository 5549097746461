import React, { useEffect, useRef, useContext, useState } from 'react';
import { DataContext } from '../../utils/DataContext';
import FileInput from './FileInput';
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import axios from 'axios';
import ReactPlayer from 'react-player';
import ShareComponent from './ShareComponent';
import { FaShareAlt } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

export default function Messages({ messages, loading, custom, setCustom, image, setImage, handleSend, setDisabled }) {

  const { t } = useTranslation();
  const messagesEndRef = useRef(null);
  const { user, handleSnackbarOpen } = useContext(DataContext); 
  const [videoId, setVideoId] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [loadingVideo, setLoadingVideo] = useState(false);
  const [open, setOpen] = useState(false)

  

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, videoUrl, loadingVideo]);

  useEffect(() => {
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage.role === 'assistant') {
        try {
          const parsedContent = JSON.parse(lastMessage.content);
          if (parsedContent.isJoke) {
            console.log('Joke detected:', parsedContent);
            console.log("Custom: ", custom)
            console.log('Joke detected:', parsedContent);
  
            const generateJokeVideo = async () => {
              try {
                let response = null;
                if(user.image && custom === 'captured'){
                  response = await axios.post(`${process.env.REACT_APP_API_URL}/api/jokes/create`, {
                    email: user.email,
                    joke: parsedContent.message,
                    image: user.image,
                    custom: custom,
                    gender: user.gender || "male",
                  });
                }else{
                  response = await axios.post(`${process.env.REACT_APP_API_URL}/api/jokes/create`, {
                    email: user.email,
                    joke: parsedContent.message,
                    custom: custom,
                    gender: user.gender || "male",
                  });
                }
                console.log('Video Id:', response.data.videoId);
                setVideoId(response.data.videoId);
              } catch (error) {
                console.error('Error generating joke video:', error);
              }
            };
  
            generateJokeVideo();
          }
        } catch (error) {
          console.error('Error parsing message content:', error);
          handleSnackbarOpen(error.message || error, 'error');
        }
      }
    }
    // eslint-disable-next-line
  }, [messages]);

  useEffect(() => {
    
    if (videoId) {
      setLoadingVideo(true)
      const interval = setInterval(async () => {
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/jokes/get`, {
            videoId:videoId,
          });
          console.log("Logging response: ",response.data)
          if (response.data.result_url) {
            setVideoUrl(response.data.result_url);
            setLoadingVideo(false)
            clearInterval(interval);
          }
        } catch (error) {
          console.error('Error getting video URL:', error);
          setLoadingVideo(false)
          //handleSnackbarOpen(error.message || error, 'error');
        }
      }, 1000); 

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [videoId]);
  //bg-[#d1dcfc]

  return (
    <div className='absolute top-[80px] bottom-[80px] left-[5%] right-[5%] overflow-auto px-4 py-5 bg-white rounded-xl flex flex-col'>
      {messages?.map((message, index) => (
        <div key={index} className='w-full flex flex-col'>
          {message?.role === 'assistant' ? (
            <div>
              {
                (index === messages.length -1 && JSON.parse(message?.content)?.requestFile === true) ?
                  <div className='w-full flex flex-col items-start'>
                    <div className='w-full flex justify-start'>
                      <p className='bg-gray-200 text-slate-800 px-3 py-2 rounded-lg text-justify'>{JSON.parse(message.content).message}</p>
                    </div>
                    <FileInput 
                      custom={custom} 
                      setCustom={setCustom} 
                      image={image} 
                      setImage={setImage} 
                      handleSend={handleSend} 
                      setDisabled={setDisabled}
                    />
                  </div>
                    :
                  <>
                    {
                      JSON.parse(message?.content)?.isJoke ? 
                        <div className='w-full flex justify-start'>
                          <p className='bg-gray-200 text-slate-800 px-3 py-2 rounded-lg text-justify'>{t("startingVideoGeneration")}</p>
                        </div>
                        :
                        <div className='w-full flex justify-start'>
                          <p className='bg-gray-200 text-slate-800 px-3 py-2 rounded-lg text-justify'>{JSON.parse(message.content).message}</p>
                        </div>

                    }
                  </>
              }
            </div>
          ) : (
            <div className='w-full flex flex-col justify-end '>
                <div className='w-full flex justify-end my-5'>
                  <p className='bg-c1Light text-white px-3 py-2 rounded-lg text-justify'>{message.content}</p>
                </div>
            </div>
          )}
        </div>
      ))}
      {
        loading && (
          <div className='w-full flex flex-col items-center justify-center'>
            <p className='text-c1 font-bold mt-3 mb-1'>{t("loading")}</p>
            <AiOutlineLoading3Quarters className='fill-c1 animate-spin w-10 h-10'/>
          </div>
        )
      }
      {
        loadingVideo && (
          <div className='w-full flex flex-col items-center justify-center'>
            <p className='text-c1 font-bold mt-3 mb-1'>{t("generatingVideo")}</p>
            <AiOutlineLoading3Quarters className='fill-c1 animate-spin w-10 h-10'/>
          </div>
        )
      }
      {videoUrl && (
        <div className='w-full mt-5 pb-[10vh] flex flex-col items-center'>
          <ReactPlayer url={videoUrl} controls className='rounded-lg' width='100%' />
          <div className='flex flex-row items-center justify-center mt-2 space-x-4'>
            <a href={videoUrl} download className='px-4 py-2 bg-c2 text-white rounded-lg'>{t("download")}</a>
            <button className=' flex flex-row items-center justify-center space-x-2 px-4 py-2 bg-c2 text-white rounded-lg' onClick={() => setOpen(true)}>
              <p>{t("share")}</p>
              <FaShareAlt className='w-[20px] h-[20px]' />
            </button>
          </div>
          <ShareComponent 
            videoUrl={videoUrl} 
            open={open}
            onClose={() => setOpen(false)}
          />
          <div className='w-full flex items-center justify-center mt-3'>
            <button 
              className=' flex items-center justify-center space-x-2 px-4 py-2 bg-c1 text-white font-bold rounded-lg' 
              onClick={() =>{
                setVideoUrl(null)
                setDisabled(false)
                setCustom("notPicked")
                handleSend(t("wantAnotherJoke"))
              }}
            >
              <p>{t("newJoke")}</p>
            </button>
          </div>
        </div>
      )}
      <div ref={messagesEndRef} />
    </div>
  );
}
