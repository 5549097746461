import { useContext } from "react";
import { DataContext } from "../../utils/DataContext";
import { Snackbar } from "@mui/material";
import { Alert } from "@mui/material";

// snackbar possible status: error, warning, info, success
// Corresponding colors in hex: #d32f2f, #f5aa00, #1976d2, #388e3c

export default function DataSnackbar() {
  const { snackbarState, setSnackbarState } = useContext(DataContext);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarState({
      open: false,
      message: "",
      status: "success",
    });
  };

  return (
    <Snackbar
      open={snackbarState.open}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      onClose={handleClose}
      message={snackbarState.message}
    >
      <Alert
        onClose={handleClose}
        severity={snackbarState.status}
        sx={{
          color:
            snackbarState.status === "success"
              ? "#388e3c"
              : snackbarState.status === "info"
              ? "#1976d2"
              : snackbarState.status === "warning"
              ? "#f5aa00"
              : "#d32f2f",
          backgroundColor: "#f5f5f5",
        }}
      >
        {snackbarState.message}
      </Alert>
    </Snackbar>
  );
}