import React, { useEffect } from "react";

export default function WebComponent({ children }) {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="hidden md:flex flex-col w-full min-h-[100vh] bg-gradient-to-br from-c1Light via-c1 to-c1Dark items-center justify-center">
      <p className="text-4xl text-white">Please use a mobile device to access this page...</p>
    </div>
  );
}