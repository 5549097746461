import React, { useState, useContext } from 'react';
import { Drawer } from '@mui/material';
import { DataContext } from '../../utils/DataContext';
import f from '../../assets/f.svg'
import { TbLogout } from "react-icons/tb";
import { GrDocumentLocked } from "react-icons/gr";
import { IoDocumentTextOutline } from "react-icons/io5";
import { MdOutlinePlayLesson } from "react-icons/md";

import { LuUserCircle2 } from 'react-icons/lu';
import { useTranslation } from 'react-i18next';

export default function Header({setShowTutorial}) {

  const {t} = useTranslation();

  const { logout } = useContext(DataContext);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className='flex flex-row justify-between items-center px-[5%] py-5'>
      <div className='text-white'>
        <img src={f} alt='f' className='w-[40px]' />
      </div>
      <div className='text-white' onClick={handleOpen}>
        <LuUserCircle2 className='text-white w-[40px] h-[40px]' />
      </div>
      <Drawer anchor='right' open={open} onClose={handleClose}>
        <div className='w-[60vw]'>
          <div className='flex flex-row items-center space-x-1 w-full hover:bg-slate-100 text-c1 text-left px-3 py-3'>
            <IoDocumentTextOutline className='text-c1 w-[20px] h-[20px]' />
            <a href="https://finconecta.atlassian.net/wiki/spaces/FCSM/pages/1650688002/Terms+Conditions" target="_blank" rel="noopener noreferrer" className='font-bold' onClick={(e) => {e.preventDefault(); window.open(e.target.href);}}>
              {t('termsAndConditions')}
            </a>
          </div>
          <div className='flex flex-row items-center space-x-1 w-full hover:bg-slate-100 text-c1 text-left px-3 py-3'>
            <GrDocumentLocked className='text-c1 w-[18px] h-[18px]' />
            <a href="https://finconecta.atlassian.net/wiki/spaces/FCSM/pages/1650589698/Privacy+Policy" target="_blank" rel="noopener noreferrer" className='font-bold' onClick={(e) => {e.preventDefault(); window.open(e.target.href);}}>
              {t('privacyPolicy')}
            </a>
          </div>
          <div 
            className='flex flex-row items-center space-x-1 w-full hover:bg-slate-100 text-c1 text-left px-3 py-3'
            onClick={()=>{
              handleClose();
              setShowTutorial(true);
            }}  
          >
            <MdOutlinePlayLesson className='text-c1 w-[20px] h-[20px]' />
            <p className='font-bold'>Tutorial</p>
          </div>
          <div 
            className='flex flex-row items-center space-x-1 w-full hover:bg-slate-100 text-c1 text-left px-3 py-3'
            onClick={logout}  
          >
            <TbLogout className='text-c1 w-[20px] h-[20px]' />
            <p className='font-bold'>
              {t('logout')}
            </p>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

