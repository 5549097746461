import React, { useEffect } from "react";

export default function MobileComponent({ children }) {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="md:hidden flex flex-col w-full min-h-[100vh] bg-gradient-to-br from-c1Light via-c1 to-c1Dark">{children}</div>
  );
}