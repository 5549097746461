// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes gradientAnimation {
  0% {
    background-position: -20% 50%;
  }
  50% {
    background-position: 170% 50%;
  }
  100% {
    background-position: -20% 50%;
  }
}

.animated-text {
  position: relative;
  display: inline-block;
  font-weight: inherit;
  font-size: inherit;
}

.animated-text::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  background: linear-gradient(90deg, var(--bg-color), var(--bg-color) 20%, var(--secondary-color) 25%, var(--main-color) 50%, var(--secondary-color) 75%, var(--bg-color) 80%, var(--bg-color));
  background-size: 200% 100%;
  background-size: 200% 'auto';
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: gradientAnimation 10s infinite;
}
`, "",{"version":3,"sources":["webpack://./src/components/layouts/styles/animatedText.css"],"names":[],"mappings":"AAAA;EACE;IACE,6BAA6B;EAC/B;EACA;IACE,6BAA6B;EAC/B;EACA;IACE,6BAA6B;EAC/B;AACF;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE,wBAAwB;EACxB,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,6LAA6L;EAC7L,0BAA0B;EAC1B,4BAA4B;EAC5B,6BAA6B;EAC7B,qBAAqB;EACrB,kBAAkB;EAClB,yCAAyC;AAC3C","sourcesContent":["@keyframes gradientAnimation {\n  0% {\n    background-position: -20% 50%;\n  }\n  50% {\n    background-position: 170% 50%;\n  }\n  100% {\n    background-position: -20% 50%;\n  }\n}\n\n.animated-text {\n  position: relative;\n  display: inline-block;\n  font-weight: inherit;\n  font-size: inherit;\n}\n\n.animated-text::before {\n  content: attr(data-text);\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 200%;\n  height: 100%;\n  background: linear-gradient(90deg, var(--bg-color), var(--bg-color) 20%, var(--secondary-color) 25%, var(--main-color) 50%, var(--secondary-color) 75%, var(--bg-color) 80%, var(--bg-color));\n  background-size: 200% 100%;\n  background-size: 200% 'auto';\n  -webkit-background-clip: text;\n  background-clip: text;\n  color: transparent;\n  animation: gradientAnimation 10s infinite;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
