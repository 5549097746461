import React, {useContext} from 'react';
import { DataContext } from '../../utils/DataContext';
import Modal from '@mui/material/Modal';
import {
  WhatsappShareButton,
  TwitterShareButton,
  TelegramShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  FacebookMessengerShareButton,
  WhatsappIcon,
  TelegramIcon,
  FacebookIcon,
  LinkedinIcon,
  FacebookMessengerIcon,
  XIcon,
} from 'react-share';
import { FaRegCopy } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

export default function ShareComponent({ videoUrl, open, onClose }) {
  const {handleSnackbarOpen} = useContext(DataContext);
  const { t } = useTranslation();
  const shareUrl = videoUrl;
  const title = "Check out this video!";

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="share-modal-title"
      aria-describedby="share-modal-description"
      className="flex items-center justify-center"
    >
      <div className="w-[70%] max-w-md p-6 bg-white rounded-lg shadow-md">
        <h2 id="share-modal-title" className="text-xl font-bold mb-4 text-c1">{t('shareThisVideo')}</h2>
        <div id="share-modal-description" className="w-full flex flex-col space-y-3">
          <WhatsappShareButton url={shareUrl} title={title}>
            <div className='flex flex-row items-center justify-start space-x-4 font-semibold'>
              <WhatsappIcon size={32} round />
              <p>Whatsapp</p>
            </div>
          </WhatsappShareButton>
          <FacebookMessengerShareButton url={shareUrl} appId="your_facebook_app_id">
            <div className='flex flex-row items-center justify-start space-x-4 font-semibold'>
              <FacebookMessengerIcon size={32} round />
              <p>Messenger</p>
            </div>
          </FacebookMessengerShareButton>
          <FacebookShareButton url={shareUrl} quote={title}>
            <div className='flex flex-row items-center justify-start space-x-4 font-semibold'>
              <FacebookIcon size={32} round />
              <p>Facebook</p>
            </div>
          </FacebookShareButton>
          <LinkedinShareButton url={shareUrl} title={title}>
            <div className='flex flex-row items-center justify-start space-x-4 font-semibold'>
              <LinkedinIcon size={32} round />
              <p>LinkedIn</p>
            </div>
          </LinkedinShareButton>
          <TwitterShareButton url={shareUrl} title={title}>
            <div className='flex flex-row items-center justify-start space-x-4 font-semibold'>
              <XIcon size={32} round /> 
              <p>X (Twitter)</p>
            </div>
          </TwitterShareButton>
          <TelegramShareButton url={shareUrl} title={title}>
            <div className='flex flex-row items-center justify-start space-x-4 font-semibold'>
              <TelegramIcon size={32} round />              
              <p>Telegram</p>
            </div>
          </TelegramShareButton>
          <div className='w-full mt-5 flex flex-row items-center justify-center'>
            <p 
              className='text-slate-900 font-bold mr-2 underline'
              onClick={()=>{
                // Logic to copy to clipboard and show a toast on succes or on error
                navigator.clipboard.writeText(shareUrl).then(() => {
                  handleSnackbarOpen(t('copyLinkSuccess'),'success');
                }, (err) => {
                  handleSnackbarOpen(t('copyLinkError'), 'error' );
                });

              }}
            >
              {t('copyLink')}
            </p>
            <FaRegCopy
              className='cursor-pointer fill-c1'
              // Copy to clipboard
              onClick={()=>{
                // Logic to copy to clipboard and show a toast on succes or on error
                navigator.clipboard.writeText(shareUrl).then(() => {
                  handleSnackbarOpen(t('copyLinkSuccess'),'success');
                }, (err) => {
                  handleSnackbarOpen(t('copyLinkError'), 'error' );
                });

              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
