import React, { useState, useEffect, useCallback, useContext } from 'react';
import axios from 'axios';
import { DataContext } from '../../utils/DataContext';
import MobileComponent from '../layouts/MobileComponent';
import WebComponent from '../layouts/WebComponent';
import Header from '../layouts/Header';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Input from '../layouts/Input';
import Messages from '../layouts/Messages';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const Chat = () => {

  const { t } = useTranslation();

  const { user } = useContext(DataContext);
  const [sessionId, setSessionId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false)
  const [custom, setCustom] = useState('notPicked')
  const [image, setImage] = useState(null)
  const [disabled, setDisabled] = useState(true)

  const handleOpen = () => {
    setShowTutorial(true);
  };

  const handleClose = () => {
    setShowTutorial(false);
  };

  const startConversation = useCallback(async () => {
    try {
      setLoading(true);
      console.log('startConversation called');
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/jokes/start`, {
        lng: i18next.language,
      });     
      console.log(response.data)
      setSessionId(response.data.sessionId);
      setMessages([{ role: 'assistant', content: response.data.message }]);
      setLoading(false);
    } catch (error) {
      console.log('Error starting conversation:', error);
      setLoading(false);
    }
  }, []);

  const sendMessage = async (message) => {
    try {
      setLoading(true);
      setDisabled(true)
      setMessages((prev) => [...prev, { role: 'user', content: message }]);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/jokes/continue`, { sessionId, userMessage: message });
      setMessages((prev) => [...prev, { role: 'assistant', content: response.data.message }]);
      setLoading(false);
      setDisabled(false)
    } catch (error) {
      console.log('Error sending message:', error);
      setLoading(false);
      setDisabled(false)
    }
  };

  useEffect(() => {
    if (user) {
      startConversation();
    }
  }, [user, startConversation]);

  useEffect(() => {
    if (user.tutorial === false) {
      handleOpen()
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log(messages)
    // eslint-disable-next-line
  }, [messages]);


  const handleSend = (input) => {
    if (input.trim()) {
      sendMessage(input);
      setInput('');
    }
  };

  return (
    <>
      <WebComponent/>
      <MobileComponent>
        <Header setShowTutorial={setShowTutorial}/>
        <Messages 
          messages={messages} 
          loading={loading} 
          setCustom={setCustom} 
          custom={custom} 
          image={image} 
          setImage={setImage} 
          handleSend={handleSend}
          setDisabled={setDisabled}
        />
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={showTutorial}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={showTutorial}>
            <div 
              className='flex flex-col bg-slate-300 absolute top-[50%] left-[50%] transform translate-y-[-50%] translate-x-[-50%] w-[94vw] rounded-[15px] py-5 px-5'
            >
              <div className='text-slate-800 text-justify flex flex-col'>
                {
                  i18next.language === 'en' &&
                  <p className='font-bold text-c1'>Welcome!</p>
                }
                {
                  i18next.language === 'es' &&
                  <p className='font-bold text-c1'>¡Bienvenido!</p>
                }
                {
                  i18next.language === 'pt' &&
                  <p className='font-bold text-c1'>Bem-vindo!</p>
                }
                <div className='text-sm mt-2'>
                  {
                    i18next.language === 'en' &&
                    <p>
                      I'm Kevin, your AI Joke Master! Create and share personalized jokes with your own face. 
                      Simply choose a selfie and a topic like finance, technology, sports, or everyday life, 
                      and I will craft a joke for you. Ready for a laugh? Let’s go!
                    </p>
                  }
                  {
                    i18next.language === 'es' &&
                    <p>
                      ¡Soy Kevin, tu Maestro de Chistes IA! Crea y comparte chistes personalizados con tu propia cara. 
                      Simplemente elige una selfie y un tema como finanzas, tecnología, deportes o la vida cotidiana, 
                      y crearé un chiste para ti. ¿Listo para reír? ¡Vamos!
                    </p>
                  }
                  {
                    i18next.language === 'pt' &&
                    <p>
                      Eu sou o Kevin, seu Mestre de Piadas IA! Crie e compartilhe piadas personalizadas com o seu próprio rosto. 
                      Basta escolher uma selfie e um tema como finanças, tecnologia, esportes ou a vida cotidiana, 
                      e eu criarei uma piada para você. Pronto para rir? Vamos lá!
                    </p>
                  }
                </div>
              </div>
              <div className='w-full flex justify-end'>
                <button 
                  className='text-white bg-c1 py-1 px-3 rounded-lg mt-3'
                  onClick={handleClose}
                >
                  {t('gotIt')}
                </button>
              </div>
            </div>
          </Fade>
        </Modal>
        <Input 
          input={input}
          setInput={setInput}
          handleSend={handleSend}
          disabled={disabled}
        />
        <p className="absolute bottom-[2px] left-[50%] text-[12px] transform translate-x-[-50%] text-white font-thin">Powered by Finconecta ®</p>
      </MobileComponent>
    </>
  );
};

export default Chat;
