import { Routes, Route } from "react-router-dom";
import Main from "./components/pages/Main";
import Welcome from "./components/pages/Welcome";
import Chat from "./components/pages/Chat";
import Authenticator from "./components/layouts/Authenticator";
import DataProvider from "./utils/DataContext";
import DataSnackbar from "./components/layouts/Datasnackbar";
import { createTheme, ThemeProvider } from "@mui/material";


const theme = createTheme({
  palette: {
    primary: {
      main: "#4b31dd",
      light: "#5436f9",
      dark: "#323093",
    },
    secondary: {
      main: "#67ddbc",
      light: "#9AE8D2",
      dark: "#56B89D",
    },
  },
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <DataProvider>
          <Routes>
            <Route path="/" element={<Welcome />} />
            <Route path="/authenticate" element={<Main />} />
            <Route path="/chat" element={<Authenticator><Chat /></Authenticator>} />
          </Routes>
          <DataSnackbar />
        </DataProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
