import React, { createContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const DataContext = createContext();

export default function DataProvider({ children }) {
  const { t } = useTranslation();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: '',
    status: '',
  });
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (token) {
      try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        fetchProfile();
      } catch (error) {
        delete axios.defaults.headers.common['Authorization'];
        setUser(null);
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleSnackbarOpen = (message = "Success!", status = "success") => {
    setSnackbarState({
      open: true,
      message: message,
      status: status,
    });
    setTimeout(() => {
      setSnackbarState({
        open: false,
        message: "",
        status: "",
      });
    }, 2500);
  };

  const fetchProfile = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/profile`);
      setUser(response.data.user);
      setLoading(false);
    } catch (error) {
      setUser(null);
      setLoading(false);
      navigate('/authenticate');
    }
  }, [navigate]);

  const login = async (email, password) => {
    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/login`, { email, password });
      console.log(response)
      if(response.data.user){
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
        setUser(response.data.user);
        handleSnackbarOpen(t("loggedInSuccessfully"), 'success')
      }else{
        handleSnackbarOpen(response.data, 'error')
        setUser(null);
        navigate('/authenticate');
      }
      setLoading(false);
      navigate('/chat');
    } catch (error) {
      setLoading(false);
      navigate('/authenticate');
      console.log(error)
      handleSnackbarOpen(error.message || error, 'error')
    }
  };

  const signup = async (email, name, lastName, companyName, companyTitle, gender) => {
    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/signup`, {
        email,
        name,
        lastName,
        companyName,
        companyTitle,
        gender
      });
      axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
      setUser(response.data.user);
      setLoading(false);
      navigate('/chat');
      handleSnackbarOpen(t("userCreatedSuccessfully"), 'success')
    } catch (error) {
      setLoading(false);
      navigate('/authenticate');
      handleSnackbarOpen(error.message || error, 'error')
    }
  };

  const logout = async() => {
    try{
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/logout`);
      console.log(response)
      delete axios.defaults.headers.common['Authorization'];
      setUser(null);
      navigate('/');
      setLoading(false);
      handleSnackbarOpen(t("loggedOutSuccessfully"), 'success')
    }catch(error){
      console.log(error)
      setUser(null);
      navigate('/');
      setLoading(false);
      handleSnackbarOpen(error.message || error, 'error')
    }
  };

  return (
    <DataContext.Provider
      value={{
        user,
        setUser,
        login,
        signup,
        logout,
        fetchProfile,
        handleSnackbarOpen,
        snackbarState,
        setSnackbarState,
        loading
      }}
    >
      {children}
    </DataContext.Provider>
  );
}
