import React, { useRef, useContext, useState } from 'react';
import { DataContext } from '../../utils/DataContext';
import Webcam from 'react-webcam';
import { GiCancel } from "react-icons/gi";
import axios from 'axios';
import imageCompression from 'browser-image-compression';
import { MdFileUpload } from "react-icons/md";
import { FaCamera } from "react-icons/fa6";
import { useTranslation } from 'react-i18next';
import Modal from '@mui/material/Modal';

export default function FileInput({ custom, setCustom, image, setImage, handleSend, setDisabled }) {

  const { t } = useTranslation();
  const { user } = useContext(DataContext);
  const fileInputRef = useRef(null);
  const webcamRef = useRef(null);
  const [base64Image, setBase64Image] = useState(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const compressedFile = await imageCompression(file, {
          maxSizeMB: 1,
          maxWidthOrHeight: 800,
        });

        const reader = new FileReader();
        reader.onloadend = () => {
          setBase64Image(reader.result.split(',')[1]);
          setImage(reader.result);
          setCustom('captured');
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error('Error compressing image:', error);
      }
    }
  };

  const openFilePicker = () => {
    fileInputRef.current.click();
  };

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setBase64Image(imageSrc.split(',')[1]);
    setImage(imageSrc);
    setCustom('captured');
  };

  const savePhoto = async (base64Image) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/edit`, { email: user.email, image: base64Image });
    console.log(response.data);
  };

  return (
    <div className='w-full flex flex-col items-center mt-3'>
      {custom === 'notPicked' && (
        <div className='flex flex-row items-center justify-center space-x-6 mt-4'>
          <button
            className='bg-c1 text-white py-1 px-3 font-bold rounded-lg'
            onClick={() => setCustom('customize')}
          >
            {t('customize')}
          </button>
          <button
            className='bg-c2 text-white py-1 px-3 font-bold rounded-lg'
            onClick={() => {
              handleSend(t('useGenericAvatar'));
              setImage(null);
              setCustom('avatar');
              setDisabled(false);
            }}
          >
            {t('useAvatar')}
          </button>
        </div>
      )}
      {custom === 'customize' && (
        <div className='flex flex-row items-center justify-center space-x-6 mt-4'>
          <button
            className='flex flex-col items-center justify-center text-c1 font-bold'
            onClick={openFilePicker}
          >
            <MdFileUpload className='w-[25px] h-[25px]' />
            <p className='text-xs'>{t('upload')}</p>
          </button>
          <button
            className='flex flex-col items-center justify-center text-c1 font-bold'
            onClick={() => setCustom('takePicture')}
          >
            <FaCamera className='w-[25px] h-[25px]' />
            <p className='text-xs'>{t('takeSelfie')}</p>
          </button>
          <button
            className='bg-red-500 text-white py-1 px-3 font-bold rounded-lg'
            onClick={() => setCustom('notPicked')}
          >
            {t('cancel')}
          </button>
        </div>
      )}
      <input
        type="file"
        accept="image/png, image/jpeg, image/jpg"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      <Modal
        open={custom === 'takePicture'}
        onClose={() => setCustom('notPicked')}
        aria-labelledby="take-picture-modal"
        aria-describedby="modal-to-take-picture"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100vh',
        }}
      >
        <div className='w-full max-w-xs mx-auto min-h-[50vh] p-4 bg-white rounded-lg shadow-lg flex flex-col items-center justify-center'>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            className='w-full rounded-lg'
          />
          <div className='flex flex-row items-center justify-center space-x-4 mt-4'>
            <button
              className='bg-red-500 text-white py-1 px-3 font-bold rounded-lg'
              onClick={() => setCustom('notPicked')}
            >
              {t('cancel')}
            </button>
            <button
              className='bg-c2 text-white py-1 px-3 font-bold rounded-lg'
              onClick={capture}
            >
              {t('capture')}
            </button>
          </div>
        </div>
      </Modal>
      {image &&
        <div className='relative w-full flex flex-col justify-end mt-4'>
          <GiCancel
            className='absolute top-3 right-3 fill-c2 w-[25px] h-[25px]'
            onClick={() => {
              setImage(null);
              setCustom('notPicked');
            }}
          />
          <img src={image} alt="Selected" className='max-w-full h-auto rounded-lg' />
          <button
            className='mt-4 bg-c2 py-1 px-3 text-white font-bold rounded-lg w-auto'
            onClick={() => {
              handleSend(t("imageSentCorrectly"));
              savePhoto(base64Image);
              setImage(null);
              setCustom('captured');
              setDisabled(false);
            }}
          >
            {t('usePhoto')}
          </button>
        </div>
      }
    </div>
  );
}
