import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import i18next from 'i18next';
import global_en from './components/i18n/locales/en/translation.json';
import global_pt from './components/i18n/locales/pt/translation.json';
import global_es from './components/i18n/locales/es/translation.json';
import { I18nextProvider } from 'react-i18next';

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    en: {
      translation: global_en,
    },
    pt: {
      translation: global_pt,
    },
    es: {
      translation: global_es,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </BrowserRouter>
);
