import React, { useContext, useEffect } from 'react';
import { DataContext } from '../../utils/DataContext';
import { useNavigate } from 'react-router-dom';
import FullScreenLoader from './FullScreenLoader';

const Authenticator = ({ children }) => {
  const { user, loading, fetchProfile } = useContext(DataContext);
  const navigate = useNavigate();

  useEffect(() => {
    console.log('Authenticator triggered')
    if (!user) {
      fetchProfile().catch(() => {
        navigate('/authenticate');
      });
    }
  }, [user, fetchProfile, navigate]);

  if (loading) {
    return <FullScreenLoader />;
  }

  return user ? children : null;
};

export default Authenticator;
