import logo from "../../assets/logo.svg"

export default function FullScreenLoader() {
  return (
    <>
      <div className="hidden md:flex flex-col w-full">
        <div
          className="w-full h-[100vh]"
        >
          <div className="w-full h-full flex flex-col items-center justify-center">
            <div className="w-[150px] h-[150px] flex items-center justify-center loader">
              <img src={logo} alt="loader-logo" width={150} />
            </div>
          </div>
        </div>
      </div>
      <div className="md:hidden flex flex-col w-full items-center justify-center">
        <div
          className="w-full h-[100vh] bg-gradient-to-br from-c1Light via-c1 to-c1Dark"
        >
          <div className="w-full h-full flex flex-col items-center justify-center">
            <div className="flex items-center justify-center loader animate-bounce">
              <img src={logo} alt="loader" className="w-[250px]" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}