import React from 'react'
import { IoSend } from "react-icons/io5";
import { useTranslation } from 'react-i18next';

export default function Input({input, setInput, handleSend, disabled=false}) {

  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault()
    handleSend(input)
  }
  return (
    <div>
      <form
        className='w-[90vw] absolute bottom-[35px] left-[50%] transform translate-x-[-50%] bg-white rounded-full px-4 flex flex-row items-center'
        onSubmit={(e) => handleSubmit(e)}
      >
        <input
          disabled={disabled}
          type='text'
          placeholder={t('messageKevin')}
          className='w-full bg-transparent outline-none h-10'
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
        <button onClick={()=>handleSend(input)}>
          {
            disabled ?
            <IoSend className='text-slate-200 w-6 h-6'/>
            :
            <IoSend className='text-c2 w-6 h-6'/>
          }
        </button>
      </form>
    </div>
  )
}
