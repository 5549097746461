import React from 'react';
import './styles/animatedText.css';

const AnimatedText = ({ text, size, thickness, mainColor, secondaryColor, color="#000" }) => {
  const textStyle = {
    fontSize: size,
    fontWeight: thickness,
    position: 'relative',
    display: 'inline-block',
    '--main-color': mainColor,
    '--secondary-color': secondaryColor,
    '--bg-color': color,
  };

  return (
    <div
      className="animated-text"
      style={textStyle}
      data-text={text}
    >
      {text}
    </div>
  );
};

export default AnimatedText;