import React, { useContext, useState, useEffect } from "react";
import { DataContext } from "../../utils/DataContext";
import FullScreenLoader from "../layouts/FullScreenLoader";
import { useNavigate } from "react-router-dom";
import WebComponent from "../layouts/WebComponent";
import MobileComponent from "../layouts/MobileComponent";
import logo from "../../assets/logo.svg";
import kevinCover from "../../assets/kevinCover.svg";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag";
import AnimatedText from "../layouts/AnimatedText";

export default function Welcome() {
  const { loading } = useContext(DataContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [currentText, setCurrentText] = useState(0);

  const texts = [
    {
      one: 'Hi! I am Kevin,',
      two: 'your Ai Joke Master.',
      three: 'Ready for some fun?',
    },
    {
      one: '¡Hola! Soy Kevin,',
      two: 'tu IA de chistes.',
      three: '¿Listo para divertirte?',
    },
    {
      one: 'Oi! Eu sou o Kevin,',
      two: 'sua IA de piadas.',
      three: 'Pronto para se divertir?',
    }
  ];

  const changeLanguage = (lng) => {
    i18next.changeLanguage(lng);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentText(
        (prevText) => (prevText + 1) % texts.length
      );
    }, 4000);
    return () => clearInterval(interval);
  }, [texts.length]);

  return (
    <>
      {loading ? (
        <FullScreenLoader />
      ) : (
        <>
          <WebComponent />
          <MobileComponent>
            <div className="flex flex-col relative w-full h-[100vh] px-[5%] overflow-hidden">
              <img src={logo} alt="logo" className="w-[200px]" />
              <div className='w-full flex items-center justify-center'>
                <div className='flex flex-col items-center justify-center'>
                  <AnimatedText
                    text={texts[currentText].one}
                    size="30px"
                    thickness="bold"
                    mainColor="#67ddbc"
                    secondaryColor="#9AE8D2"
                    color="#fff"
                  />
                  <AnimatedText
                    text={texts[currentText].two}
                    size="30px"
                    thickness="bold"
                    mainColor="#67ddbc"
                    secondaryColor="#9AE8D2"
                    color="#fff"
                  />
                  <AnimatedText
                    text={texts[currentText].three}
                    size="30px"
                    thickness="bold"
                    mainColor="#67ddbc"
                    secondaryColor="#9AE8D2"
                    color="#fff"
                  />
                </div>
              </div>
              <div className="w-full flex flex-col items-center justify-center">
                <img src={kevinCover} alt="kevin-cover" className="w-[400px]" />
                <div className="w-full flex flex-row items-center justify-center space-x-3">
                  <button
                    onClick={() => {
                      changeLanguage('en');
                      navigate('/authenticate');
                    }}
                    className="flex flex-row items-center justify-center space-x-2 bg-c2 text-white px-3 py-2 font-bold rounded-lg shadow-sm shadow-slate-400"
                  >
                    <p>English</p>
                    <ReactCountryFlag
                      countryCode="US"
                      svg
                      style={{
                        width: '20px',
                        height: '20px',
                      }}
                      title="US"
                    />
                  </button>
                  <button
                    onClick={() => {
                      changeLanguage('es');
                      navigate('/authenticate');
                    }}
                    className="flex flex-row items-center justify-center space-x-2 bg-c2 text-white px-3 py-2 font-bold rounded-lg shadow-sm shadow-slate-400"
                  >
                    <p>Español</p>
                    <ReactCountryFlag
                      countryCode="ES"
                      svg
                      style={{
                        width: '20px',
                        height: '20px',
                      }}
                      title="ES"
                    />
                  </button>
                  <button
                    onClick={() => {
                      changeLanguage('pt');
                      navigate('/authenticate');
                    }}
                    className="flex flex-row items-center justify-center space-x-2 bg-c2 text-white px-3 py-2 font-bold rounded-lg shadow-sm shadow-slate-400"
                  >
                    <p>Português</p>
                    <ReactCountryFlag
                      countryCode="BR"
                      svg
                      style={{
                        width: '20px',
                        height: '20px',
                      }}
                      title="BR"
                    />
                  </button>
                </div>
              </div>
              <p className="absolute bottom-[2px] left-[50%] text-[12px] transform translate-x-[-50%] text-white font-thin">{t("poweredBy")}</p>
            </div>
          </MobileComponent>
        </>
      )}
    </>
  );
}
